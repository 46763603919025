import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

/**
 * A combination of the classNames and twMerge functions.
 * This allows for conditional classes as well as allowing twMerge to handle cleanup and overriding previous Tailwind classes of similar type.
 * @param classes
 * @returns A class string to add to the classname prop on a component.
 */
export const cn = (...classes: classNames.ArgumentArray) => {
  return twMerge(classNames(...classes));
};
