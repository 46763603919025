import Image from 'next/image';
import Link from 'next/link';
import { FC, PropsWithChildren } from 'react';
import Container from 'sections/common/Container';
import { cn } from 'utils/cn';

type BlockImageCtaSectionProps = {
  className?: string;
  branding: 'Rotation' | 'Udlejningsmaeglerne';
  logo?: {
    url: string;
    width: number;
    height: number;
    alternativeText: string;
  };
  text: string;
  ctaLabel: string;
  ctaLink: string;
  backgroundImage: {
    url: string;
    width: number;
    height: number;
    alternativeText: string;
    objectPosition?: string;
  };
};

export const BlockImageCtaSection: FC<PropsWithChildren<BlockImageCtaSectionProps>> = ({
  className,
  branding,
  logo,
  text,
  ctaLabel,
  ctaLink,
  backgroundImage,
}) => {
  return (
    <Container>
      <div
        className={cn(
          `relative overflow-hidden rounded-3xl border border-slate-200`,
          [branding === 'Rotation' && 'font-brand-rotation-sans', branding === 'Udlejningsmaeglerne' && 'font-brand-udlejningsmaeglerne-sans'],
          className,
        )}
      >
        <div className="absolute -z-10 h-full w-full">
          <Image
            src={backgroundImage.url}
            alt={backgroundImage.alternativeText}
            layout="fill"
            objectFit="cover"
            objectPosition={backgroundImage.objectPosition}
            priority
            quality={100}
          />
        </div>
        {branding === 'Udlejningsmaeglerne' && <div className="absolute -z-10 h-full w-full bg-slate-900/40 md:bg-slate-900/20" />}
        <div
          className={cn('z-1 flex max-w-full flex-col gap-8 p-8 md:max-w-[45%] md:p-20', [
            branding === 'Rotation' && 'md:max-w-[55%]',
            branding === 'Udlejningsmaeglerne' && 'bg-slate-900/40',
          ])}
        >
          {logo && (
            <div className="max-w-44">
              <Image src={logo?.url} alt={logo?.alternativeText} width={logo?.width} height={logo?.height} quality={100} />
            </div>
          )}
          <p
            className={cn('text-white', [
              branding === 'Rotation' && 'text-2xl leading-8 md:text-3xl md:leading-10',
              branding === 'Udlejningsmaeglerne' && 'text-xl leading-7',
            ])}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {branding === 'Rotation' && (
            <div>
              <Link href={ctaLink}>
                <button className="rounded-md bg-slate-900 px-8 py-2 text-white">{ctaLabel}</button>
              </Link>
            </div>
          )}
          {branding === 'Udlejningsmaeglerne' && (
            <div>
              <Link href={ctaLink}>
                <button className="group flex items-center gap-4 rounded-full bg-slate-900 px-8 py-3 text-sm text-white">
                  {ctaLabel}
                  <div className="relative h-2 w-10 overflow-hidden transition-[width] duration-500 group-hover:w-14">
                    <div className="absolute right-0">
                      <ArrowIcon />
                    </div>
                  </div>
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

const ArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="59" height="8" viewBox="0 0 59 8" fill="none">
      <path
        d="M58.3536 4.35355C58.5488 4.15829 58.5488 3.84171 58.3536 3.64645L55.1716 0.464466C54.9763 0.269204 54.6597 0.269204 54.4645 0.464466C54.2692 0.659728 54.2692 0.976311 54.4645 1.17157L57.2929 4L54.4645 6.82843C54.2692 7.02369 54.2692 7.34027 54.4645 7.53553C54.6597 7.7308 54.9763 7.7308 55.1716 7.53553L58.3536 4.35355ZM0 4.5H58V3.5H0V4.5Z"
        fill="white"
      />
    </svg>
  );
};
